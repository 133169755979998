.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


/* bootstrap css */
.input-group-text {
  /*設定每個 input-group-addon 都有固定寬度 */
  min-width:110px;  
}

/* bootstrap css */

.list-title {
  font-weight: bolder;
  margin-bottom: .5rem!important;
  border-bottom: 1px solid #007bff!important;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.NavLink{
  color: rgba(0,0,0,.5);
  padding-right: .5rem;
  padding-left: .5rem;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
}

mark{line-height:1.4em;}